import styled from 'styled-components'

export const StyledInput = styled.input`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  // border-bottom-left-radius: 5px;
  // border-top-left-radius: 5px;
  padding-left: 10px;

  :focus {
    border: none;
    outline: none;
  }

  @media (max-width: 768px) {
    width: 320px
  }
  
   @media (max-width: 480px) {
    width: 240px
  }

  @media (max-width: 390px) {
    width: 200px
  }
`
