import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box, Image, useDisclosure, useOutsideClick
} from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'
import { v4 as uuid } from 'uuid'

export const GuestContentItem = ({ items, title }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!items.length) {
    return <div>No notifications to display</div>
  }
  console.log(items)

  return (
    <>
      {[...items].reverse().map((item) => (
        <Box
          key={uuid()}
          cursor="pointer"
          onClick={() => {
            if (title === 'connector') {
              window.open(item?.link)
            } else {
              console.log(' ')
            }
            // if (item.is_viewed && getProfileItemStatus !== 'PENDING') {
            //   handleOpenModal(item)
            // } else if (getProfileItemStatus === 'PENDING') {
            //   console.log('stop')
            // } else {
            //   dispatch(postMakeNotificationSeen(item?.id))
            // }
          }}
          onLoad={() => {
            if (localStorage.getItem('openedNotifId') === String(item?.id)) {
              localStorage.removeItem('openedNotifId')
            //   handleOpenModal(item)
            }
          }}
          sx={{
            minWidth: '120px',
            marginTop: '5px'
          }}
          data-name="notificationItem"
        //   pointerEvents={isNotifViewing === false ? 'auto' : 'none'}
        >
          <Image
            // boxSize="70px"
            width="108px"
            height="108px"
            objectFit="cover"
            src={item?.image || ImagesPng.DefaultImage}
            alt={item?.title}
            // opacity={isNotifViewing === false ? 1 : 0.7}
            borderRadius="5px"
          />
        </Box>
      ))}
    </>
  )
}
