import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setXmppLiveLocation } from 'store/chat/slice'
import { usersSelectors } from 'store'
import { LOCATION_TYPE } from 'utils/constants'

const COORD_DELTA = 0

export const useGeoLocation = () => {
  const [watcherId, setWatcherId] = useState(Number.NaN)
  const [coordUpdateThreshold, setCoordUpdateThreshold] = useState(Number.NaN)
  const [prevCoord, setPrevCoord] = useState({ lat: Number.NaN, lng: Number.NaN })

  const dispatch = useDispatch()
  const { activeProfile } = useSelector(usersSelectors)

  const onSuccess = (loc) => {
    setCoordUpdateThreshold(loc.accuracy + COORD_DELTA)
    const isFirstTime = (Number.isNaN(prevCoord.lat) || Number.isNaN(prevCoord.lng))
    const isRegularUpdate = ((loc.coords.latitude > prevCoord.lat + coordUpdateThreshold)
      || (loc.coords.longitude > prevCoord.lat + coordUpdateThreshold))
    if (isFirstTime || isRegularUpdate) {
      setPrevCoord({ lat: loc.coords.latitude, lng: loc.coords.longitude })
      dispatch(setXmppLiveLocation(loc.coords))
      const event = new CustomEvent('custom-chat-update_gps_coordinates')
      window.dispatchEvent(event)
    }
  }

  const onError = (error) => {
    // we do not reset coordinates on error
    // so last seen coordinate will be available
    if ('geolocation' in navigator) {
      console.error(error)
    } else {
      console.error('Geolocation not supported')
    }
  }

  useEffect(() => {
    const activeID = activeProfile?.id
    const activeLocType = activeProfile?.location_type
    const clearWatcher = () => {
      if (Number.isNaN(watcherId)) {
        return
      }
      navigator.geolocation.clearWatch(watcherId)
      setWatcherId(Number.NaN)
      setPrevCoord({ lat: Number.NaN, lng: Number.NaN })
    }
    if (
      activeID
      && (
        activeLocType?.[LOCATION_TYPE.LiveLocation] === 0
        || activeLocType?.[LOCATION_TYPE.LiveApproximate] === 1
      )
    ) {
      clearWatcher()
      const id = navigator.geolocation.watchPosition(
        onSuccess,
        onError,
        { maximumAge: 0, enableHighAccuracy: true, timeout: Infinity }
      )

      setWatcherId(id)
    } else {
      clearWatcher()
    }
  }, [activeProfile?.location_type])

  return {}
}
